let map;
const siteRoot = window.location.protocol + '//' + window.location.host;

const placeList =
    typeof nk_custom_properties !== 'undefined'
        ? nk_custom_properties.propertiesList
        : nk_properties_vars.propertiesList;

// Default map settings centered on Leeds
const customLat = 53.801277;
const customLng = -1.548567;
const customMapData = {
    initialZoom: 12,
    zoom: 11,
    center: {
        lat: customLat,
        lng: customLng,
    },
    placeList: placeList,
    mapStyle: nk_properties_vars.mapStyle,
};

function initMap() {
    const mapHolder = document.getElementById('map');
    if (!mapHolder) {
        console.warn('Map container not found.');
        return;
    }

    console.log('Initializing Google Maps...');
    map = new google.maps.Map(mapHolder, {
        zoom: customMapData.initialZoom,
        center: customMapData.center,
        styles: customMapData.mapStyle,
        streetViewControl: false,
        mapTypeControl: false,
        scrollwheel: false,
    });

    map.data.addGeoJson(customMapData.placeList, {
        idPropertyName: 'propertyId',
    });

    // Handle marker interactions
    map.data.addListener('click', event => {
        map.data.forEach(place => {
            place.setProperty('isSelected', false);
        });

        const propertyId = event.feature.getProperty('propertyId');
        if (document.querySelector('.property-popup-wrap .property-active') !== null) {
            document.querySelector('.property-popup-wrap .property-active').classList.remove('property-active');
        }
        document.querySelector(`.property-popup-wrap .property-${propertyId}`).classList.add('property-active');
        event.feature.setProperty('isSelected', true);
        updateMapStyle();
    });

    // Handle closing of property details
    const closePlaceDetails = document.querySelectorAll('.property-box .close-property');
    closePlaceDetails.forEach(place => {
        place.addEventListener('click', e => {
            const currentBox = e.target.closest('[data-property-id]');
            const currentPlaceId = currentBox.dataset.propertyId;
            const currentActive = map.data.getFeatureById(currentPlaceId);
            currentBox.classList.remove('property-active');
            currentActive.setProperty('isSelected', false);
            updateMapStyle();
        });
    });

    function updateMapStyle() {
        const customIcon = `${siteRoot}/wp-content/themes/ere-property/assets/images/ico-map-pin.svg`;
        const selectedIcon = `${siteRoot}/wp-content/themes/ere-property/assets/images/ico-map-pin-selected.svg`;
        map.data.setStyle(function (place) {
            if (place.getProperty('isSelected') === true) {
                return {
                    icon: {
                        url: selectedIcon,
                        scaledSize: new google.maps.Size(40, 50),
                        anchor: new google.maps.Point(20, 50),
                    },
                };
            } else {
                return {
                    icon: {
                        url: customIcon,
                        scaledSize: new google.maps.Size(32, 40),
                        anchor: new google.maps.Point(16, 40),
                    },
                };
            }
        });
    }

    function showAllPoints() {
        const bounds = new google.maps.LatLngBounds();
        if (placeList.features.length > 1) {
            map.data.forEach(place => {
                bounds.extend(place.getGeometry().get());
            });
            map.fitBounds(bounds);
        } else {
            map.data.forEach(place => {
                const centerOn = place.getGeometry().get();
                map.setCenter(centerOn);
            });
        }
    }

    updateMapStyle();
    showAllPoints();
}
